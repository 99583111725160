import React, { useState, useEffect, createContext } from "react";

import axios from "axios";
import "./Activity.css";
import articulation_img from "../src/assets/articulation.png";
import bg_activity from "../src/assets/bg_activity.jpg";
import activity_button from "../src/assets/activity_button.png";
import about_img from "../src/assets/about_img.png";
import slider_img1 from "../src/assets/slider_img1.png";
import slider_img3 from "../src/assets/slider_img3.png";
import a from "../src/assets/a.png";
import back_to_card from "../src/assets/back_to_card.png";
import activity_left_arrow from "../src/assets/activity_left_arrow.png";

const Activitycard = () => {
  const [isMainModalOpen, setIsMainModalOpen] = useState(false);
  const [isArticulationModalOpen, setIsArticulationModalOpen] = useState(false);
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const [isFluencyModalOpen, setIsFluencyModalOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [isProceedClicked, setIsProceedClicked] = useState(false);
  const [selected, setSelected] = useState(null); // Track the selected div

  const [articulations, setArticulations] = useState([]); // Articulation data

  const [positions, setPositions] = useState([]);
  const [selectedTabs, setSelectedTabs] = useState([]);
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const colors = ["#4CBB16", "#FF5733", "#FFC300", "#900C3F", "#3498DB"]; // Distinct colors
  const [selectedButtons, setSelectedButtons] = useState([]);

  const [isSubcategoryModalOpen, setIsSubcategoryModalOpen] = useState(false);

  const categories = ["Initial", "Middle", "Final"];
  const words = ["Word", "Phrase", "Sentence"];

  const [selectedItems, setSelectedItems] = useState([]); // Categories/Words
  const [selectedArticulations, setSelectedArticulations] = useState([]); // Articulations
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility
  const [selectedArticulation, setSelectedArticulation] = useState(null); // Selected articulation
  const [storedSelections, setStoredSelections] = useState({}); // To store selections for each articulation

  const handleSelect = (articulation) => {
    // Check if the articulation is already selected
    const isAlreadySelected = selectedArticulations.includes(
      articulation.sound_name
    );

    if (!isAlreadySelected) {
      // Add the articulation to the selection list
      setSelectedArticulations((prev) => [...prev, articulation.sound_name]);

      // Clear the modal for fresh selections
      setSelectedItems([]);
    } else {
      // Restore selections from storage for already-selected articulation
      setSelectedItems(storedSelections[articulation.sound_name] || []);
    }

    setSelectedArticulation(articulation); // Set the currently selected articulation
    setIsModalOpen(true); // Open the modal
  };

  const handleClick = (item) => {
    setSelectedItems((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
    );
  };

  const handleDone = () => {
    // Save the selections in React state
    const updatedSelections = {
      ...storedSelections,
      [selectedArticulation.sound_name]: selectedItems,
    };
    setStoredSelections(updatedSelections);

    console.log("Selections Saved:", updatedSelections);

    // Close the modal without clearing selectedItems
    setIsModalOpen(false);
  };

  const handleProceed = () => {
    // Clear the stored selections in React state
    setStoredSelections({});
    setSelectedArticulations([]); // Clear all selected values
    alert("Selections have been cleared.");

    // Open the modal
    setIsNewModalOpen(true);
  };

  useEffect(() => {
    const savedSelections = JSON.parse(
      sessionStorage.getItem("articulationSelections") // Use sessionStorage here
    );
    if (savedSelections) {
      setStoredSelections(savedSelections);
    }
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedArticulation(null);
  };

  const getBackgroundColor = (soundName) =>
    selectedArticulation?.sound_name === soundName ? "#ABB838" : "#f0f0f0";

  const handleNextClick = () => {
    if (
      selectedButtons.length === 1 &&
      selectedButtons.includes("Articulation")
    ) {
      setIsArticulationModalOpen(true);
      setIsMainModalOpen(false);
    } else if (
      selectedButtons.length === 2 &&
      selectedButtons.includes("Language") &&
      selectedButtons.includes("Fluency")
    ) {
      setIsLanguageModalOpen(true);
      setIsMainModalOpen(false);
    }
    if (
      selectedButtons.length === 2 &&
      selectedButtons.includes("Articulation") &&
      selectedButtons.includes("Language")
    ) {
      setIsArticulationModalOpen(true);
      setIsMainModalOpen(false);
    }
    if (
      selectedButtons.length === 3 &&
      selectedButtons.includes("Articulation") &&
      selectedButtons.includes("Language") &&
      selectedButtons.includes("Fluency")
    ) {
      setIsArticulationModalOpen(true);
      setIsMainModalOpen(false);
    }
    if (selectedButtons.length === 1 && selectedButtons.includes("Language")) {
      setIsLanguageModalOpen(true);
      setIsMainModalOpen(false);
    }
    if (selectedButtons.length === 1 && selectedButtons.includes("Fluency")) {
      setIsFluencyModalOpen(true);
      setIsMainModalOpen(false);
    }
    if (
      selectedButtons.length === 2 &&
      selectedButtons.includes("Fluency") &&
      selectedButtons.includes("Articulation")
    ) {
      setIsArticulationModalOpen(true);
      setIsMainModalOpen(false);
    }
  };

  const handleButtonClick = (buttonLabel) => {
    setSelectedButtons(
      (prevSelected) =>
        prevSelected.includes(buttonLabel)
          ? prevSelected.filter((label) => label !== buttonLabel) // Remove if already selected
          : [...prevSelected, buttonLabel] // Add if not selected
    );
  };

  useEffect(() => {
    axios
      .get("https://virtualtxai.com/api/articulations") // Replace with actual endpoint
      .then((response) => {
        console.log("Articulations:", response.data.data);
        setArticulations(response.data.data); // Assuming response.data.data is an array
      })
      .catch((error) => {
        console.error("Error fetching articulation data:", error);
      });
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isModalOpen]);

  // Fetch data from API
  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const response = await axios.get(
          "https://virtualtxai.com/api/articulation-data"
        ); // Replace with actual endpoint
        setPositions(response.data.positions); // Assuming response.data.positions is an array

        console.log("Positions:", response.data.positions);
        console.log("Subcategories:", response.data.subcategories);
      } catch (error) {
        console.error("Error fetching articulation positions:", error);
      }
    };

    fetchPositions();
  }, []);
  // Define unique background colors for each subcategory
  const subcategoryColors = {
    Word: "#ff6347", // Red
    Phrase: "#32cd32", // Green
    Sentence: "#6495ed", // Cornflower Blue
    Initial: "#ffb6c1", // Light Pink
    Middle: "#ffa500", // Orange
    Final: "#8a2be2", // Blue Violet
  };

  const closeNewModal = () => {
    setIsNewModalOpen(false); // Close the new modal
  };

  // Toggle Subcategory Modal
  const toggleSubcategoryModal = () => {
    setIsSubcategoryModalOpen((prevState) => !prevState);
  };

  // Toggle Articulation Modal
  const toggleArticulationModal = () => {
    setIsMainModalOpen(false); // Close main modal
    setIsLanguageModalOpen(false); // Close Language modal
    setIsArticulationModalOpen(!isArticulationModalOpen); // Toggle Articulation modal
  };

  // Handle Reset button click
  const handleReset = () => {
    setSelectedArticulations([]); // Clear all selected values
    setSelected(null); // Reset the selected value
  };

  // Close all modals
  const closeModal = () => {
    setIsMainModalOpen(false);
    setIsArticulationModalOpen(false);
    setIsLanguageModalOpen(false);
    setIsFluencyModalOpen(false);
  };

  const handleOverlayButtonClick = () => {
    if (isProceedClicked) {
      setIsNewModalOpen(true);
      // Handle the different modal logic here
    } else {
      setIsMainModalOpen(true); // Open the main modal
    }
  };

  return (
    <div className="video-container">
      <iframe
        src="https://vtxgames.com/public/backend/assets/game_unzip_file/1811708053661215/vtx/"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
      <button className="overlay-button" onClick={handleOverlayButtonClick}>
        <img
          src={activity_button}
          alt="Articulation"
          className="img-fluid w-25"
        />
      </button>

      {isMainModalOpen && (
        <div className="modal-container" onClick={closeModal}>
          <div className="modal-content " onClick={(e) => e.stopPropagation()}>
            <div className="d-flex justify-content-center flex-column gap-4 ">
              <div className="d-flex justify-content-center gap-4 position-relative">
                {["Articulation", "Language", "Fluency"].map((buttonLabel) => (
                  <button
                    key={buttonLabel}
                    className={`articulation_button px-4 py-5 fw-bold fs-2 d-flex justify-content-center align-items-center ${
                      selectedButtons.includes(buttonLabel)
                        ? "selected-bg"
                        : "default-bg"
                    }`}
                    onClick={() => handleButtonClick(buttonLabel)}
                  >
                    <img
                      src={articulation_img}
                      alt={buttonLabel}
                      className="img-fluid w-25"
                    />
                    {buttonLabel}
                  </button>
                ))}
              </div>
              <div className="d-flex justify-content-between">
                <button
                  className="close-button px-3 py-2  mb-3 "
                  onClick={closeModal}
                >
                  Previous
                </button>
                <button
                  className="px-3 py-2  mb-3 close-button"
                  onClick={handleNextClick}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Articulation Modal */}
      {isArticulationModalOpen && (
        <div
          className="modal-container"
          onClick={closeModal}
          style={{
            overflowY: "auto", // Enable vertical scrolling if content exceeds screen
            maxHeight: "100vh", // Limit height to the viewport
            padding: "20px", // Add padding to avoid content touching edges
            boxSizing: "border-box",
          }}
        >
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
            style={{
              background: "transparent",
              top: "1rem",
              margin: "0 auto",
              width: "100%",
              maxWidth: "1390px", // Center the modal with a maximum width
            }}
          >
            <div className="d-flex justify-content-between align-items-center mt-5">
              {/* Articulation Button (Active) */}
              <div>
                <button
                  style={{
                    marginTop: "20px",
                    padding: "10px 20px",
                    color: "#fff",
                    background: "#4CBB16",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  className="px-3 py-2 rounded me-3"
                >
                  Articulation
                </button>

                {/* Fluency Button (Disabled if Articulation and Language are selected) */}
                <button
                  onClick={() => setIsFluencyModalOpen(true)}
                  style={{
                    marginTop: "20px",
                    padding: "10px 20px",
                    color: "#fff",
                    background: "#4CBB16", // Active button styling
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  className="px-3 py-2 rounded me-3"
                >
                  Fluency
                </button>

                {/* Language Button (Active) */}
                <button
                  onClick={() => setIsLanguageModalOpen(true)}
                  style={{
                    marginTop: "20px",
                    padding: "10px 20px",
                    color: "#fff",
                    background: "#4CBB16", // Active button styling
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  className="px-3 py-2 rounded me-3"
                >
                  Language
                </button>
              </div>

              {/* Reset Button */}
              <button
                style={{
                  marginTop: "20px",
                  padding: "10px 20px",
                  background: "#4CBB16",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={handleReset} // Reset functionality
              >
                Reset
              </button>
            </div>

            <div
              className="row mt-4"
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {articulations.map((articulation) => (
                <div
                  key={articulation.id}
                  onClick={() => handleSelect(articulation)}
                  className="col-md-1 d-flex justify-content-center align-items-center"
                  style={{
                    margin: "10px",
                    height: "6rem",
                    background: selectedArticulations.includes(
                      articulation.sound_name
                    )
                      ? "#4CBB17"
                      : "#f8f9fa", // Highlight selected
                    borderRadius: "10px",
                    padding: "20px",
                    textAlign: "center",
                    cursor: "pointer",
                    minWidth: "100px",
                    maxWidth: "150px",
                  }}
                >
                  <h5
                    style={{
                      color: selectedArticulations.includes(
                        articulation.sound_name
                      )
                        ? "#fff"
                        : "grey", // Adjust text color
                      fontSize: "1.2rem",
                      fontWeight: "700",
                    }}
                  >
                    {articulation.sound_name}
                  </h5>
                </div>
              ))}
            </div>

            <div className="d-flex justify-content-between gap-5 align-items-center">
              <button
                style={{
                  marginTop: "20px",
                  padding: "10px 20px",
                  background: "#4CBB16",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  closeModal();
                  setIsMainModalOpen(true);
                }} // Reset functionality
              >
                Prev
              </button>

              {/* Proceed Button */}
              {selectedItems.length > 0 && (
                <button
                  style={{
                    marginTop: "20px",
                    padding: "10px 20px",
                    background: "#4CBB17",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  onClick={handleProceed}
                >
                  Proceed
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div
          className="modal"
          style={{
            display: "block",
            position: "fixed",
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            overflow: "hidden", // Hides scrollbars within the modal
          }}
          onClick={handleCloseModal}
        >
          <div
            className="modal-content"
            style={{
              position: "relative",
              margin: "10% auto",
              padding: "20px",
              top: "-114px",
              backgroundColor: "transparent",
              borderRadius: "10px",
              maxWidth: "50rem",
              overflow: "hidden", // Ensures no scrolling inside the modal
            }}
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
          >
            <h3>{selectedArticulation?.sound_name}</h3>
            <p>
              {selectedArticulation?.description || "No description available."}
            </p>

            <div>
              <h2 className="text-light">Select a sub category and position</h2>
              <div className="words_section">
                {/* Categories */}
                <div className="row d-flex justify-content-center gap-3 my-5">
                  {categories.map((category, index) => (
                    <div
                      key={index}
                      className="col-md-2 py-5 px-5 rounded d-flex justify-content-center align-items-center"
                      style={{
                        background: selectedItems.includes(category)
                          ? "#4CBB17"
                          : "#f8f9fa",
                        color: selectedItems.includes(category)
                          ? "#fff"
                          : "#000",
                        cursor: "pointer",
                        border: "1px solid #ddd",
                      }}
                      onClick={() => handleClick(category)}
                    >
                      <h5 className="m-0 p-0">{category}</h5>
                    </div>
                  ))}
                </div>

                <hr className="bg-light p-1" />

                {/* Words Section */}
                <div className="row d-flex justify-content-center gap-3 my-5">
                  {words.map((word, index) => (
                    <div
                      key={index + categories.length}
                      className="col-md-2 py-5 px-5 rounded d-flex justify-content-center align-items-center"
                      style={{
                        background: selectedItems.includes(word)
                          ? "#4CBB17"
                          : "#f8f9fa",
                        color: selectedItems.includes(word) ? "#fff" : "#000",
                        cursor: "pointer",
                        border: "1px solid #ddd",
                      }}
                      onClick={() => handleClick(word)}
                    >
                      <h5 className="m-0 p-0">{word}</h5>
                    </div>
                  ))}
                </div>
                <div>
                  <button
                    onClick={handleDone}
                    style={{
                      marginTop: "20px",
                      padding: "10px 20px",
                      background: "#4CBB17",
                      color: "#fff",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Language Modal */}
      {isLanguageModalOpen && (
        <div className="modal-container" onClick={closeModal}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
            style={{ background: "orange" }}
          >
            <span className="close-button" onClick={closeModal}>
              &times;
            </span>
            <div className="d-flex justify-content-start gap-2">
              {/* Disabled Articulation Button */}
              <button
                style={{
                  marginTop: "0px",
                  padding: "10px 20px",
                  color: "grey",
                  background: "#ddd",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "not-allowed",
                }}
                disabled
              >
                Articulation
              </button>

              {/* Active Language Button */}
              <button
                style={{
                  marginTop: "0px",
                  padding: "10px 20px",
                  color: "#fff",
                  background: "#4CBB16",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Language
              </button>

              {/* Active Fluency Button */}
              <button
                style={{
                  marginTop: "0px",
                  padding: "10px 20px",
                  color: "#000",
                  background: "#fff",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  closeModal();
                  setIsFluencyModalOpen(true);
                }}
              >
                Fluency
              </button>
            </div>

            {/* Modal Content */}
            <h2>Language Modal</h2>
            <p>This is the content for the Language modal.</p>
          </div>
        </div>
      )}

      {/* Fluency Modal */}
      {isFluencyModalOpen && (
        <div
          className="modal-container"
          onClick={closeModal}
          style={{
            overflowY: "auto", // Enable vertical scrolling if content exceeds screen
            maxHeight: "100vh", // Limit height to the viewport
            padding: "20px", // Add padding to avoid content touching edges
            boxSizing: "border-box",
          }}
        >
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
            style={{
              background: "#f4f4f4",
              top: "1rem",
              margin: "0 auto",
              width: "100%",
              maxWidth: "800px", // Center the modal with a maximum width
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <span className="close-button" onClick={closeModal}>
              &times;
            </span>
            <div className="d-flex justify-content-start gap-2">
              <button>Articulation</button>
              <button
                style={{
                  marginTop: "0px",
                  padding: "10px 20px",
                  color: "#fff",
                  background: "#4CBB16",

                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Fluency{" "}
              </button>
              <button>Language</button>
            </div>
            <h2 className="fw-bold fs-1 text-center">Fluency Modal</h2>
            <p style={{ textAlign: "center", margin: "20px 0" }}>
              This is the content for the Fluency modal. Add relevant details
              here.
            </p>
            <div className="d-flex justify-content-center">
              <button
                style={{
                  marginTop: "20px",
                  padding: "10px 20px",
                  background: "#4CBB16",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* New Modal */}
      {isNewModalOpen && (
        <div className="modal-container" onClick={closeNewModal}>
          <div
            className="modal-content w-75"
            onClick={(e) => e.stopPropagation()}
            style={{ background: "transparent" }}
          >
            <div className="d-flex justify-content-between">
              <div className="d-flex justify-content-center gap-3 align-items-center w-100">
                <p
                  className="fw-bold text-light"
                  style={{ fontSize: "1.6rem" }}
                >
                  Articulation cards
                </p>
                <input
                  type="text"
                  className="px-3 py-3 w-75 rounded"
                  placeholder="F found (Words middle)"
                />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-2 left_section mt-4  d-flex flex-column justify-content-top align-items-center">
                <div
                  className="close_button_new_modal px-2 py-2 "
                  style={{ color: "#fff", background:"#4CBB16",borderRadius:"50%", width:"", padding:"1.9rem", fontSize:"2rem" }}
                  onClick={closeNewModal}
                >
                 <img
                      src={back_to_card}
                      alt="Articulation"
                      className="img-fluid" style={{width:"4rem"}}
                    />
                </div>
                <div className="mt-5 d-flex flex-column justify-content-center align-items-center gap-4">
                  {" "}
                  <div style={{ backgroundColor:"#4CBB16" ,borderRadius:"50%", width:"50%",border: "4px solid #fff" , position:"relative"}}>
                    <img
                      src={a}
                      alt="Articulation"
                      className="img-fluid" style={{width:"9rem"}}
                    />
                 
                  </div>{" "}
             
                 
                </div>
                {/* Content for the left section */}
              </div>
              <div
                className="col-md-10 right_section bg-light"
                style={{ padding: "2rem 3rem", borderRadius: "12px" }}
              >
                {/* Bootstrap Carousel */}
                <div
                  id="carouselExampleControls"
                  className="carousel slide"
                  data-bs-ride="false" // Ensure auto-slide is not set
                  data-bs-interval="false" // Disable interval-based auto sliding
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        src={about_img}
                        className="d-block w-100 img-fluid"
                        alt="Slide 1"
                        style={{ height: "500px", objectFit: "cover" }}
                      />
                      <div className="carousel-caption d-none d-md-block w-75">
                        <div className="d-flex justify-content-end w-100">
                          <button
                            style={{ border: "none", borderRadius: "7px" }}
                            className="px-5 py-3 rounded"
                          >
                            Image description
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <img
                        src={slider_img1}
                        className="d-block w-100 img-fluid"
                        alt="Slide 2"
                        style={{ height: "500px", objectFit: "cover" }}
                      />
                      <div className="carousel-caption d-none d-md-block">
                        <h5 className="text-light">Slider 2</h5>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <img
                        src={slider_img3}
                        className="d-block w-100 img-fluid"
                        alt="Slide 3"
                        style={{ height: "500px", objectFit: "cover" }}
                      />
                      <div className="carousel-caption d-none d-md-block">
                        <h5 className="text-light">Slider 3</h5>
                      </div>
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Activitycard;
